import { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { MdFilterAlt, MdCopyAll } from "react-icons/md"

/** @jsx jsx */
import { jsx, Button, Container } from 'theme-ui'
import { withTheme } from '@emotion/react'

const Export = (props) => {
  const data = useStaticQuery(pageQuery)
  const entreprises = data.allPrismicEntreprise
  const localgraphSites = data.site.siteMetadata.localGraphSites
  const totalCount = entreprises.totalCount
  const companies = entreprises.edges
  let categories = []

  const [filteredComps, setFilteredComps] = useState(companies)
  const [filters, setFilters] = useState({
    category: 'all',
    verified: true,
    unverified: true,
    noemail: true, 
    email: true, 
  })
  const [token, setToken] = useState({
    go: false,
    p: "",
  })

  // console.log(filteredComps)

  // Get a list of unique categories and their companies count

  companies.forEach( edge => {
    let catName = edge.node.data.categories[0].category.uid
    if ( !categories.find( c => c.name === catName )) categories.push({'name': catName})
  })  

  categories.forEach( cat => {
    let count = companies.filter( ({node: co}) => co.data.categories[0].category.uid === cat.name ).length
    cat.count = count
  })

  // Filtering with categories

  const handleCatSelectChange = (e) => {
    let selection = e.target.value
    setFilters(prev => {
      let newFilters = {...prev}
      newFilters.category = selection
      filterCompanies(newFilters)
      return newFilters
    })
  }

  // Filtering  with checkboxes

  const checkboxHandler = (e) => {
    setFilters(prev => {
      let newFilters = prev
      newFilters[e.target.name] = !prev[e.target.name]
      filterCompanies(newFilters)
      return newFilters
    })
  }

  // Main filtering function

  const filterCompanies = (filters) => {
    let filtered = [...companies]

    // If a category is selected
    if ( filters.category !== 'all' ) {
      filtered = filtered.filter( ({node: corp}) => corp.data.categories[0].category.uid === filters.category )
    }

    // Unclick "verified" => enlève les entreprises vérifiées (laisser toutes les entreprise sauf celles vérifiées)
    if ( filters.verified === false ) {
      filtered = filtered.filter( ({node: corp}) => corp.data.verified !== true )
    }

    // Unclick "UNverified" => enlève les entreprises non vérifiées (laisser toutes entrepries sauf non vérifiées)
    if ( filters.unverified === false ) {
      filtered = filtered.filter( ({node: corp}) => (
        corp.data.verified !== false &&
        corp.data.verified !== null
        ))
    }

    // Unclick "NoMail" => enlève les entreprises sans email (laisser toutes entreprises avec email)
    if ( filters.noemail === false ) {
      filtered = filtered.filter( ({node: corp}) => corp.data.contact_email !== null)
    }    

    // Unclick "Mail" => enlève les entreprises avec email (laisser toutes entreprises sans email)
    if ( filters.email === false ) {
      filtered = filtered.filter( ({node: corp}) => corp.data.contact_email === null)
    }

    setFilteredComps(filtered)
  }

  // Calculate % of category (catCount)
  // Change when filteredComps changes
  let catCount = 0
  if (filters.category !== 'all') {
    catCount = categories.find( c => c.name === filters.category).count
  }

  // Manage the auth token
  const handleSignin = (e) => {
    e.preventDefault()
    console.log(token)
    if (token.p === "DirtySorbet") {
      setToken({...token, go: true})
    }
  }

  // Calculate tags scores
  const calculateTags = (companies) => {
    // [{name: "tag1", count: 2}, {name: "tag2", count: 13}, {name: "tag3", count: 6}]
    let tagScores = []

    // for each company, take all tags and add them to the master count
    companies.forEach( ({node: corp}) => {
      let companyTags = corp.tags
      
      // Iterate on single company's tags (if exist) and update scores
      if ( companyTags.length > 0 ) {
        companyTags.forEach( tag => {
          let tagIndex = tagScores.findIndex( item => {
            return item.name === tag
          })
          if ( tagIndex >= 0 ) {
            // update score
            tagScores[tagIndex].count += 1
          } else {
            // create it
            tagScores.push( {"name": tag, "count": 1} )
          }
        })
      }
    })

    // order the whole array
    return tagScores.sort( (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
  }

  // Copy stuff

  // const handleCopy = () => {
  //   let copyThis = table()
  //   console.log(copyThis)
  //   navigator.clipboard.writeText(copyThis)
  // }

  let table = () => (
    <table sx={{
      border: '1px solid black',
      borderCollapse: 'collapse',
      width: '100%',
      'tr, th, td': {
        border: '1px solid black',
      },
      'th': {
        textAlign: 'left',
        padding: 2,
      },
      'td': {
        padding: 1,
        fontSize: '10px',
      },
      'tr:nth-of-type(even)': {
        backgroundColor: 'white',
      },
    }}>
      <thead>
        <tr sx={{
          background: 'black',
          color: 'white',
          th: {
            border: '1px solid rgba(255, 255, 255, .33)',
          }
        }}>
          <th>n°</th>
          <th>Category</th>
          <th>Name</th>
          <th>💌 Email</th>
          <th>☎️ Phone</th>
          <th>☑️ Verified</th>
          <th>🔥 1. Premium</th>
          <th>🔥 2. Boost</th>
          <th>🔥 3. Media</th>
          <th>Tags</th>
          <th>URL</th>
        </tr>
      </thead>
      
      <tbody>
        {filteredComps.map(({node: corp}, index) => {
            let category = corp.data.categories[0].category.uid
            let urlExists = localgraphSites.filter( site => site.id === category)[0]?.url

            return (
              <tr key={corp.uid} sx={{
                fontSize: 1,
                'a': {
                  color: "black", 
                  textDecoration: 'none'
                }
              }}>
                <td>{index+1}</td>
                <td><a href={urlExists && `https://${urlExists}`} target="_blank">{category}</a></td>
                <td sx={{minWidth: '300px'}}><a href={urlExists && `https://${urlExists}/${corp.uid}`} target="_blank">{corp.data.record.text}</a></td>
                <td sx={{
                  cursor: "pointer", 
                  position: "relative",
                  ':hover': {
                    'svg': {
                      opacity: '100%',
                    }
                  }
                }} 
                  onClick={() => {navigator.clipboard.writeText(corp.data.contact_email)}}>
                  {corp.data.contact_email} 
                  <MdCopyAll sx={{position: "absolute", top: "7px", right: '5px', opacity: '10%'}} />
                </td>
                <td>"{corp.data.phone_number}"</td>
                <td>{corp.data.verified ? 'yes' : ''}</td>
                <td>{corp.data.enhancedprofile ? 'yes' : ''}</td>
                <td>{corp.data.rank ? 'yes' : ''}</td>
                <td>{corp.data.paid_media ? 'yes' : ''}</td>
                <td>{corp.tags.join(', ')}</td>
                <td>{`https://www.${urlExists}/${corp.uid}`}</td>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
 
  return (
    <Layout>
      <SEO title="Table" noindex />

      <Container>
        {!token.go && <div sx={{my: 5}}>
          <h3>Dead end</h3> 
          Try your luck?!

          <form sx={{mt: 3}} onSubmit={handleSignin}>
            <input type="password" name="password" placeholder="Speak friend & enter..." 
              value={token.p} 
              sx={{p: 2}}
              onChange={e => setToken({...token, p: e.target.value})}
              />
            <input type="submit" sx={{p: 2, ml: 2}}/>
          </form>
        </div>} 
      </Container>

        {token.go &&
          <div sx={{
              py: 6,
            }}>    
            <div sx={{fontSize: '200px', lineHeight: "1"}}>{totalCount}</div>
            <h1>Companies! Export me, baby.</h1>
            <p>With great powers come great responsibilities.</p>
    
            <div sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', bg: 'white', p: 2, mb: 4}}>
              <form>
                <label><MdFilterAlt sx={{marginBottom: '-2px'}}/> Catégorie :</label>
                <select name="categories" onChange={e => handleCatSelectChange(e)}>
                  <option value="all">Tous</option>
                  {categories.sort((a, b) => b.count - a.count).map( cat => <option key={cat.name} value={cat.name}>{cat.name} ({cat.count})</option> )}
                </select>
              </form>
    
              <div>
                {filteredComps.length}{` `}
                {filters.category !== "all" && <span>({(filteredComps.length/catCount*100).toFixed(0)}%)</span>}
              </div> 
    
              <form sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div sx={{mx:2}}>
                  <input type="checkbox" name="verified" defaultChecked={filters.verified} onChange={checkboxHandler}/>
                  <label htmlFor="verified">✅Verified</label>
                </div>
                <div sx={{mx:2}}>
                  <input type="checkbox" name="unverified" defaultChecked={filters.unverified} onChange={checkboxHandler}/>
                  <label htmlFor="unverified">👻Unverified</label>
                </div>
                <div sx={{mx:2}}>
                  <input type="checkbox" name="noemail" defaultChecked={filters.noemail} onChange={checkboxHandler}/>
                  <label htmlFor="noemail">🚫Email</label>
                </div>
                <div sx={{mx:2}}>
                  <input type="checkbox" name="email" defaultChecked={filters.email} onChange={checkboxHandler}/>
                  <label htmlFor="email">💌Email</label>
                </div>
              </form>
            </div>

            <div>
              {table()}
            </div>

            {<div sx={{mt: 4}}>
              <h2>Tag cloud</h2>

              <div sx={{
                position: "relative",
              }}>
                <div sx={{
                  width: '1px', 
                  height: '100%',
                  bg: "white", opacity: ".66",
                  position: "absolute",
                  top: "0", bottom: "0", left: '30%',
                }}></div>
                {calculateTags(filteredComps).map( tag => (
                  <div sx={{
                    // fontSize: `${((tag.count / filteredComps.length)+1)*40}px`,
                    width: `${(tag.count / filteredComps.length * 100)+30}%`,
                    bg: 'primary',
                    color: 'white',
                    borderRadius: 0,
                    py: 2, px:3, m: 1,
                    display: "flex", alignItems: "center", justifyContent: "space-between",
                  }}
                    key={tag.name}>
                    <div><span sx={{bg: "white", p: 1, borderRadius: 0, color: "primary", mr: 2}}>{tag.count}</span>{tag.name}</div>
                    <div sx={{opacity: ".5"}}>({(tag.count / filteredComps.length *100).toFixed(0)}%)</div>
                  </div>
                ))}
              </div>
            </div>}
          </div>
        }

    </Layout>
  )
}

export default Export

const pageQuery = graphql`
  query exportQuery {
    site {
      siteMetadata {
        localGraphSites {
            id
            name
            url
        }
      }
    }
    allPrismicEntreprise {
      totalCount
      edges {
        node {
          uid
          tags
          data {
            verified
            enhancedprofile
            rank
            paid_media
            categories {
              category {
                uid
              }
            }
            phone_number
            contact_email
            record {
              text
            }
          }
        }
      }
    }
  }
`